@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.App {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}


img {
  width: 300px;
  margin: 10px;
}